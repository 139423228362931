<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">
        <router-link :to="getRoute(getEmployeeRouteName(item), { name: item.slugName })">{{ item.name }}</router-link>
      </h5>
      <p>
        <em v-for="title in employeeTitles(item)" :key="title">
          {{ title }}
          <br>
        </em>
      </p>
      <dl class="row">
        <dt class="col-lg-4 col-xl-3">{{ translate('phone') }}</dt>
        <dd class="col-lg-8 col-xl-9">
          <a :href="'tel:'+ item.phone">{{ formatPhone(item.phone) }}</a>
        </dd>
        <dt class="col-lg-4 col-xl-3">{{ translate('mobile') }}</dt>
        <dd class="col-lg-8 col-xl-9">
          <a :href="'tel:'+ item.mobile">{{ formatPhone(item.mobile) }}</a>
        </dd>
        <dt class="col-lg-4 col-xl-3">Email</dt>
        <dd class="col-lg-8 col-xl-9 text-nowrap">
          <a :href="'mailto:' + item.email">{{ formatEmail(item.email) }}</a>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import employeeMixins from "@/mixins/EmployeeMixins";

export default {
  mixins: [employeeMixins],
  props: ["item"]
};
</script>
